import React from 'react';
import BgCurve from './bg-curve';
import { Container, CurveContainer, Curve } from './StyledBannerCurve';
import { BackgroundColors } from '_utils/components/Corporate/Section';

export type BannerCurveProps = {};

export type BannerCurveOptions = {
  backgroundColor?: string;
  renderCurve?: (props: any) => boolean;
};

const defaultOptions = {
  offsetY: 0,
  renderCurve: null,
  backgroundColor: BackgroundColors.White
};

interface WithParamProps {
  params: {
    backgroundColour: BackgroundColors;
  };
}

// HOC to wrap component with blue background curve when displayed right under banner
export const withBannerCurve = (options: BannerCurveOptions = {}) =>
  function<T extends BannerCurveProps = BannerCurveProps>(
    WrappedComponent: React.ComponentType<T>
  ) {
    const mergedOptions = { ...defaultOptions, ...options };

    const { renderCurve, backgroundColor } = mergedOptions;

    // Create a nice displayName for React Dev Tools.
    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    const withBannerCurve: React.FC<T & WithParamProps> = (props: T & WithParamProps) => {
      if (renderCurve !== null && renderCurve(props) === false) {
        return null;
      }

      let sectionTheme = backgroundColor;

      try {
        sectionTheme = BackgroundColors[props.params.backgroundColour];
      } catch {}

      if (renderCurve(props) !== null) {
        return (
          <Container className={sectionTheme} sectionTheme={sectionTheme}>
            <CurveContainer>
              <Curve>
                <BgCurve aria-hidden={true} />
              </Curve>
            </CurveContainer>
            <WrappedComponent {...(props as T)} />
          </Container>
        );
      }

      return <WrappedComponent {...(props as T)} />;
    };

    withBannerCurve.displayName = `withBannerCurve(${displayName})`;

    return withBannerCurve;
  };
